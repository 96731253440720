"use client";

import { useEffect, useState } from "react";
import Overview from "~/components/Overview";
import New from "~/components/lists/New";
import { useLists } from "~/providers/ListsProvider";
import Layout, { LayoutContent, LayoutMain } from "~/components/layout/Layout";
import NavBar from "~/components/layout/NavBar";
import Sidebar from "~/components/layout/Sidebar";
import MobilePage from "./mobileScreen/page"; // Assuming MobilePage is imported from this path

export default function HomePage() {
  const { lists } = useLists();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width as needed
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  if (isMobile) {
    return <MobilePage />;
  }

  return (
    <Layout>
      <Sidebar />
      <LayoutMain>
        <NavBar />
        {lists.length > 0 ? (
          <div className="flex h-[calc(100vh_-_36px)] overflow-y-hidden w-full justify-center z-0 px-8">
            <div className="flex max-w-full grow flex-col justify-between">
              <Overview />
            </div>
          </div>
        ) : (
          <LayoutContent>
            <New />
          </LayoutContent>
        )}
      </LayoutMain>
    </Layout>
  );
}
