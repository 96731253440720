import { BoltIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useParams } from "next/navigation";

import { useLists } from "~/providers/ListsProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";

import { Constants, Enums } from "@openqlabs/utils";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "~/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { ToggleGroup, ToggleGroupItem } from "~/components/ui/toggle-group";
import { Sparkle } from "lucide-react";

const formSchema = z.object({
  listName: z.string().min(2).max(50),
  startDate: z.string(),
  endDate: z.string(),
  listMode: z
    .enum([
      Enums.ListMode.ULTRALIGHT,
      Enums.ListMode.LIGHT,
      Enums.ListMode.DEEP,
    ])
    .default(Enums.ListMode.LIGHT),
  monthlyReevaluation: z.enum(["enabled", "disabled"]).default("disabled"),
});

export default function New() {
  const oneMonthAgo = Constants.getFourWeeksAgoSimple();
  const now = Constants.getNowSimple();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      listName: "",
      startDate: oneMonthAgo,
      endDate: now,
      listMode: Enums.ListMode.LIGHT,
      monthlyReevaluation: "disabled",
    },
  });

  const { activeTeamAccount } = useTeamAccount();
  const { teamAccountUser } = useTeamAccountUser();
  const { createList } = useLists();
  const params = useParams();
  const { folderId } = params as { folderId: string };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    console.log("onSubmit called with values:", values);

    createList({
      name: values.listName,
      contactIds: [],
      teamAccountId: activeTeamAccount.id,
      userId: teamAccountUser?.id as string,
      startDate: new Date(values?.startDate),
      endDate: new Date(values?.endDate),
      mode: values.listMode,
      folderId: folderId as string | undefined,
      monthlyReevaluation: values.monthlyReevaluation === "enabled",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const isValid = await form.trigger();

    if (isValid) {
      const values = form.getValues();

      onSubmit(values);
    } else {
      console.log("Form errors:", form.formState.errors);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="space-y-8">
        <FormField
          control={form.control}
          name="listName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="My list name" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="listMode"
          render={({ field }) => (
            <FormItem>
              <FormLabel>List Mode</FormLabel>
              <FormControl>
                <ToggleGroup
                  className="w-fit gap-0 rounded-md"
                  onValueChange={field.onChange}
                  value={field.value}
                  type="single"
                >
                  <ToggleGroupItem
                    className="rounded-r-none px-2"
                    value={Enums.ListMode.ULTRALIGHT}
                  >
                    <Sparkle className="sm-icon mr-2" />
                    Ultra Light Mode
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    className="rounded-none px-2"
                    value={Enums.ListMode.LIGHT}
                  >
                    <BoltIcon className="sm-icon mr-2" />
                    Light Mode
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    className="rounded-l-none px-2"
                    value={Enums.ListMode.DEEP}
                  >
                    <ClockIcon className="sm-icon mr-2" />
                    Deep Mode
                  </ToggleGroupItem>
                </ToggleGroup>
              </FormControl>
              <FormDescription>
                {field.value === Enums.ListMode.ULTRALIGHT ? (
                  <>
                    Ultra Light mode will analyze all repositories provided, but
                    none of the other contributors' repositories. Useful when
                    you only want to analyze activity for a list of
                    repositories.
                  </>
                ) : field.value === Enums.ListMode.LIGHT ? (
                  <>
                    Light mode conducts a quicker analysis of a large number of
                    teams and contributors. It analyzes repositories committed
                    to most recently, providing a balance between speed and
                    depth.
                  </>
                ) : (
                  <>
                    Deep mode activates a comprehensive analysis, designed for
                    smaller groups of developers. It conducts thorough
                    backtracking through GitHub repositories, facilitating the
                    identification of when developers joined your ecosystem.
                    <br />
                    <br />
                    We can trace events up to four years in the past, examining
                    commits in GitHub repositories to determine when users
                    committed a dependency to their project that we're tracking
                    for you, or participated in a project containing your
                    dependency.
                  </>
                )}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        {Enums.ListMode.DEEP === form.getValues("listMode") && (
          <>
            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Start Date</FormLabel>
                  <FormControl>
                    <Input placeholder="YYYY-MM-DD" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="endDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>End Date</FormLabel>
                  <FormControl>
                    <Input placeholder="YYYY-MM-DD" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}
        <FormField
          control={form.control}
          name="monthlyReevaluation"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Monthly Reevaluation</FormLabel>
              <FormControl>
                <ToggleGroup
                  className="w-fit gap-0 rounded-md"
                  onValueChange={field.onChange}
                  value={field.value}
                  type="single"
                >
                  <ToggleGroupItem
                    className="rounded-r-none px-2"
                    value={"enabled"}
                  >
                    <Sparkle className="sm-icon mr-2" />
                    Enabled
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    className="rounded-l-none px-2"
                    value={"disabled"}
                  >
                    <ClockIcon className="sm-icon mr-2" />
                    Disabled
                  </ToggleGroupItem>
                </ToggleGroup>
              </FormControl>
              <FormDescription>
                Enable monthly reevaluation to automatically re-run the analysis
                on a monthly basis.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit">Submit</Button>
      </form>
    </Form>
  );
}
